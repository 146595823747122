import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@/components/Button/Button';
import { PopupModal } from 'react-calendly';
import { SxProps } from '@mui/material';
import { stopPropagation } from '@/utils/common';
import { AuthStore, CoachingStore, OrgStore } from '@/stores/index';
import { observer } from 'mobx-react-lite';

export type CalendlyEmbededProps = {
  sx: SxProps;
  small?: boolean;
  url?: string;
  disabled?: boolean;
  disableClick?: boolean;
  onOpen?: () => void;
  onClick?: () => void;
  hideButton?: boolean;
  hideModal?: boolean;
  opened?: boolean;
  setOpened?: (val: boolean) => void;
  formatUrl?: boolean;
  autoApplyUrl?: boolean;
  onAutoApplyUrl?: () => void;
  onClose?: () => void;
  sendCalendlyEventAdded?: boolean;
} & any;

const CalendlyEmbededPure: React.FC<CalendlyEmbededProps> = ({
  sx,
  children,
  variant = 'contained',
  color = 'secondary',
  icon,
  small = false,
  url = 'https://calendly.com/ashley-yesayan/book-a-demo',
  disabled,
  disableClick = false,
  onOpen,
  onClick,
  hideButton = false,
  hideModal = false,
  opened,
  setOpened,
  formatUrl = true,
  autoApplyUrl = false,
  onAutoApplyUrl,
  onClose,
  sendCalendlyEventAdded = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rootElement, setRootElement] = useState(null);

  useEffect(() => {
    setRootElement(document.getElementById('__next'));
  }, []);

  const { email } = AuthStore.user || {};
  const { name } = OrgStore.org || {};

  // url can change while the modal is opened, so we need to store it and change on open/close only
  const [appliedUrl, setAppliedUrl] = useState<string | null>(null);
  const calendlyUrl = useMemo(
    () =>
      url && formatUrl
        ? `${url}${url?.includes('?') ? '&' : '?'}email=${encodeURIComponent(
            email,
          )}&a1=${
            name || ''
          }&utm_source=ov_dashboard&utm_content=${encodeURIComponent(email)}`
        : url,
    [email, name, url, formatUrl],
  );

  useEffect(() => {
    if (hideButton || autoApplyUrl) {
      setAppliedUrl(calendlyUrl);
      if (autoApplyUrl) onAutoApplyUrl?.();
    }
  }, [hideButton, autoApplyUrl, onAutoApplyUrl, calendlyUrl]);

  return (
    <>
      {!hideButton && (
        <Button
          sx={sx}
          small={small}
          startIcon={icon}
          color={color}
          variant={variant}
          onClick={(e) => {
            stopPropagation(e);
            if (disableClick) return null;

            CoachingStore.setSendCalendlyEventAdded(sendCalendlyEventAdded);

            if (onClick) return onClick?.();

            setAppliedUrl(calendlyUrl);
            (setOpened ?? setIsOpen)(true);
            onOpen?.();
            return null;
          }}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
      {!hideModal && (
        <PopupModal
          url={appliedUrl}
          onModalClose={(e) => {
            stopPropagation(e);
            (setOpened ?? setIsOpen)(false);
            setAppliedUrl(null);
            onClose?.();
          }}
          open={!!appliedUrl && (opened ?? isOpen)}
          rootElement={rootElement}
        />
      )}
    </>
  );
};

export const CalendlyEmbeded = observer(CalendlyEmbededPure);
