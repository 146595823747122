import React, { MouseEventHandler, ReactNode, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { AnimatePresence, Variants } from 'framer-motion';
import { SxProps } from '@mui/material';

import Icon from '@/components/icons/Icon';
import { Overlay, ModalContainer, BackButton } from './Modal.styles';
import { PageAlike } from './ModalVariants/PageAlike';
import { PageAlikePropTypes } from './ModalVariants/PageAlike.types';
import ModalNewCloseButton from './ModalNewCloseButton/ModalNewCloseButton';

export interface ModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  withBackButton?: boolean;
  withCloseButton?: boolean;
  useOutsideCloseButton?: boolean;
  closeWithBg?: boolean;
  insideCloseButtonForSmScreen?: boolean;
  closeOnBackClick?: boolean;
  overlayVariants?: Variants;
  containerVariants?: Variants;
  withOverlayAnimation?: boolean;
  withContainerAnimation?: boolean;
  variant?: 'pageAlike';
  pageProps?: PageAlikePropTypes;
  children?: ReactNode;
  closeButtonSx?: SxProps;
  overlayStyle?: Record<string, string | number>;
}

const defaultOverlayVariants = {
  exit: { opacity: 0 },
  isOpen: { opacity: 1 },
  initial: { opacity: 0 },
};
const defaultContainerVariant = {
  exit: { top: '0%' },
  isOpen: { top: '0%', duration: 1000 },
  initial: { top: '30%', transition: { type: 'timing' } },
};

function Modal({
  isOpen,
  children,
  handleClose,
  withBackButton,
  withCloseButton = true,
  useOutsideCloseButton = false,
  closeWithBg,
  insideCloseButtonForSmScreen = true,
  closeOnBackClick = false,
  overlayVariants,
  containerVariants,
  withOverlayAnimation = true,
  withContainerAnimation = true,
  variant,
  pageProps = null,
  closeButtonSx,
  overlayStyle,
}: React.PropsWithChildren<ModalProps>) {
  let modalOverlayAnimation;
  let modalContainerVariants;

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';

    // make it work on component unmount
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (withOverlayAnimation) {
    modalOverlayAnimation = overlayVariants ?? defaultOverlayVariants;
  }

  if (withContainerAnimation) {
    modalContainerVariants = containerVariants ?? defaultContainerVariant;
  }

  const innerHeaderProps = useMemo(
    () =>
      pageProps?.headerProps
        ? {
            onBack: handleClose as MouseEventHandler<HTMLElement>,
            ...pageProps?.headerProps,
          }
        : {
            onBack: handleClose as MouseEventHandler<HTMLElement>,
          },
    [handleClose, pageProps],
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          key="modal"
          initial="initial"
          animate="isOpen"
          exit="exit"
          variants={modalOverlayAnimation}
          style={overlayStyle}
          onClick={closeOnBackClick ? handleClose : undefined}
        >
          <ModalContainer variants={modalContainerVariants}>
            {variant === 'pageAlike' ? (
              <PageAlike {...pageProps} headerProps={innerHeaderProps}>
                {children}
              </PageAlike>
            ) : (
              <>
                {children}
                {withBackButton && (
                  <BackButton onClick={handleClose}>
                    <Icon iconName="chevron-arrow-left" />
                  </BackButton>
                )}
                {withCloseButton && (
                  <ModalNewCloseButton
                    outsidePosition={useOutsideCloseButton}
                    withBg={closeWithBg}
                    insideForSmScreen={insideCloseButtonForSmScreen}
                    onClick={handleClose}
                    sx={closeButtonSx}
                  />
                )}
              </>
            )}
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  );
}

export default observer(Modal);
