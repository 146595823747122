import { Box, Stack } from '@mui/material';
import React from 'react';
import { getRightTransition } from './HeroSection.styles';

type Props = {
  moveRight: boolean;
};

const rotateDeg = 20;
const left = 17;

const Background = ({ moveRight }: Props) => {
  return (
    <Stack
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: '50vw',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#6CAEB4',
          position: 'absolute',
          top: '-40rem',
          left: { xs: `${left + 25}%`, sm: `${left}%` },
          bottom: '-40rem',
          width: '100vw',
          zIndex: 1,
          rotate: `-${rotateDeg + 5.5}deg`,
          boxShadow: '0px 0px 100px 0px #0000000D',
          ...getRightTransition(moveRight),
        }}
      />
      <Box
        sx={{
          backgroundColor: '#91C7CB',
          position: 'absolute',
          top: '-40rem',
          left: {
            xs: `${left + 60}%`,
            sm: `${left + 5}%`,
            md: `${left + 4}%`,
            lg: `${left + 3}%`,
          },
          bottom: '-30rem',
          width: '100vw',
          zIndex: 2,
          rotate: `-${rotateDeg}deg`,
          boxShadow: '0px 0px 100px 0px #0000000D',
          ...getRightTransition(moveRight),
        }}
      />
    </Stack>
  );
};

export default Background;
