import { TextEditorHTMLItem } from '@/types/Content/Coaching';
import { Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

type Props = {
  theme: Theme;
  lineHeight?: string;
  bodyStyle?: 'body1' | 'body2';
  textAlign?: 'left' | 'right' | 'center';
  fontSize?: string;
};

const TextEditorHTMLEl = ({
  theme,
  parent,
  bodyStyle = 'body2',
  lineHeight = '1.5 !important',
  textAlign,
  fontSize,
}: Props & { parent: TextEditorHTMLItem }) => {
  const { style, markDefs, listItem } = parent;
  const variant = ['normal', 'blockquote'].includes(style) ? bodyStyle : style;

  const isImg = useMemo(() => parent._type === 'image', [parent._type]);

  let sx: SxProps<Theme> = {};

  if (isImg)
    return parent.asset?.url ? (
      <img alt={parent.asset?._ref} src={parent.asset?.url} />
    ) : null;

  if (style === 'blockquote') {
    sx = {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      paddingLeft: theme.spacing(1),
    };
  }

  return (
    <Typography
      sx={sx}
      variant={variant as any}
      lineHeight={lineHeight}
      textAlign={textAlign}
      fontSize={fontSize}
    >
      {listItem === 'bullet' && (
        <span style={{ margin: theme.spacing(0, 2) }}>&#8226;</span>
      )}
      {parent?.children?.map((item) => {
        if (!item.text) {
          return (
            <p key={item._key} style={{ minHeight: theme.spacing(4) }}>
              {' '}
            </p>
          );
        }

        const itemStyle: any = {};
        let href: string | null = null;
        item.marks?.forEach((mark) => {
          if (mark === 'strong') {
            itemStyle.fontWeight = 700;
          } else if (mark === 'em') {
            itemStyle.fontStyle = 'italic';
          } else {
            const def = markDefs?.find((el) => el._key === mark);
            if (!def) return;
            if (def._type === 'link' && def.href) {
              href = def.href;
              itemStyle.color = theme.palette.primary.main;
              itemStyle.textDecoration = 'underline';
            } else if (def._type === 'color' && def.hex) {
              itemStyle.color = def.hex;
            }
          }
        });

        const Cmp: any = href ? 'a' : 'span';
        const cmpProps = href ? { href, target: '_blank' } : null;
        return (
          <Cmp key={item._key} style={itemStyle} {...cmpProps}>
            {item?.text}
          </Cmp>
        );
      })}
    </Typography>
  );
};

const TextEditorHTML = ({
  items,
  bodyStyle,
  lineHeight,
  sx,
  textAlign = 'left',
  fontSize,
}: { items: string | any[]; sx?: SxProps } & Pick<
  Props,
  'bodyStyle' | 'lineHeight' | 'textAlign' | 'fontSize'
>) => {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={2} sx={sx}>
      {typeof items === 'string' ? (
        <Typography variant="body2" textAlign={textAlign} fontSize={fontSize}>
          {items}
        </Typography>
      ) : (
        items?.map((el) => (
          <TextEditorHTMLEl
            key={el._key}
            parent={el}
            theme={theme}
            bodyStyle={bodyStyle}
            lineHeight={lineHeight}
            textAlign={textAlign}
            fontSize={fontSize}
          />
        ))
      )}
    </Stack>
  );
};

export default TextEditorHTML;
