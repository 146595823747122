import { CoachColorTheme } from '@/types/Content/Coaching';
import { Box, Stack, Theme, keyframes, styled } from '@mui/material';

export const ConsultantsWrapper = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    borderTop: `2px solid ${theme.palette.primary[400]}`,
    padding: theme.spacing(5, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 0),
    },
  }),
);

const getCoachBackColor = (
  theme: Theme,
  colorTheme: CoachColorTheme,
): string => {
  switch (colorTheme) {
    case CoachColorTheme.white:
      return theme.palette.grey[50];
    case CoachColorTheme.yellow:
      return theme.palette.warning[500];
    case CoachColorTheme.blue:
      return theme.palette.primary.main;
    default:
      return theme.palette.grey[50];
  }
};

export const CoachContainer = styled(Stack)<{ colorTheme?: CoachColorTheme }>`
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme, colorTheme }) =>
    getCoachBackColor(theme, colorTheme)};
  border: ${({ theme, colorTheme }) =>
    `${colorTheme === CoachColorTheme.white ? '1px' : 0} solid ${
      theme.palette.grey[100]
    }`};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(7, 9.5)};
  color: ${({ theme }) => theme.palette.grey[600]};
  cursor: pointer;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const getCoachBackImgColor = (colorTheme: CoachColorTheme): string => {
  switch (colorTheme) {
    case CoachColorTheme.white:
      return '#00000015';
    default:
      return '#ffffff56';
  }
};
export const CoachBackImgWrapper = styled(Box)<{
  colorTheme?: CoachColorTheme;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60%;
  color: ${({ colorTheme }) => getCoachBackImgColor(colorTheme)};
  z-index: 0;
`;

export const size = 120;
export const sizeHover = 230;

const transitionDuration = '.3s';
const sizeTransition = `
  transition-property: height, width;
  transition-duration: ${transitionDuration};
  transition-timing-function: ease-in-out;
`;
export const opacityTransition = `opacity ${transitionDuration} ease-in-out`;

export const StyledAvatar = styled('div')<{ url: string; hovered: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${sizeHover / 2}px;
  overflow: hidden;
  background: no-repeat right bottom / contain url(${({ url }) => url});
  transition: ${opacityTransition};
  opacity: ${({ hovered }) => (hovered ? 0 : 1)};
`;
export const StyledVideo = styled('video')<{ hovered: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${sizeHover / 2}px;
  overflow: hidden;
  transition: ${opacityTransition};
  opacity: ${({ hovered }) => (hovered ? 1 : 0)};
`;

export const StyledMediaContainer = styled(Stack)<{ hovered: boolean }>`
  width: ${({ hovered }) => (hovered ? sizeHover : size)}px;
  height: ${({ hovered }) => (hovered ? sizeHover : size)}px;
  border-radius: ${sizeHover / 2}px;
  position: absolute;
  box-shadow: -10px 4px 40px 0px #0000001a;
  background-color: #6caeb4;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${sizeTransition}
`;

export const rotateCircle = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const hoveredSize = 'calc(100% + 1.5rem)';
export const StyledBorderCircle = styled(Box)<{ hovered: boolean }>`
  position: absolute;
  width: ${({ hovered }) => (hovered ? hoveredSize : '100%')};
  height: ${({ hovered }) => (hovered ? hoveredSize : '100%')};
  border-radius: 200px;
  border: 2px ${({ hovered }) => (hovered ? 'dashed' : 'solid')} #00000033;
  ${sizeTransition}
`;

export const getQuoteColor = (
  theme: Theme,
  colorTheme: CoachColorTheme,
): string => {
  switch (colorTheme) {
    case CoachColorTheme.yellow:
      return theme.palette.warning[800];
    default:
      return '#91C7CB';
  }
};
export const getNameColor = (
  theme: Theme,
  colorTheme: CoachColorTheme,
): string => {
  switch (colorTheme) {
    case CoachColorTheme.blue:
      return theme.palette.grey[50];
    default:
      return theme.palette.primary.main;
  }
};
export const getSubTitleColor = (
  theme: Theme,
  colorTheme: CoachColorTheme,
): string => {
  switch (colorTheme) {
    case CoachColorTheme.blue:
      return theme.palette.grey[50];
    default:
      return theme.palette.grey[600];
  }
};
export const getIntroColor = (
  theme: Theme,
  colorTheme: CoachColorTheme,
): string => {
  switch (colorTheme) {
    case CoachColorTheme.white:
      return theme.palette.grey[600];
    case CoachColorTheme.yellow:
      return theme.palette.warning[900];
    case CoachColorTheme.blue:
      return theme.palette.grey[50];
    default:
      return '#91C7CB';
  }
};
