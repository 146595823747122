import { IconButton, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import Image from 'next/image';
import { Box } from '@mui/system';
import { PageAlikeModal } from './PageAlike.styles';
import { PageAlikePropTypes } from './PageAlike.types';

export const defaultHeaderProps = {
  title: 'null',
  withBackButton: true,
  onBack: null,
  color: 'common.black',
};

export const defaultPaperProps = {
  padding: [11, 21],
  color: 'common.white',
};

export function PageAlike(
  props: PropsWithChildren<PageAlikePropTypes>,
): JSX.Element {
  const { children, paperProps, headerProps, withHeader } = props;
  const innerHeaderProps = { ...defaultHeaderProps, ...headerProps };
  const innerPaperProps = { ...defaultPaperProps, ...paperProps };
  return (
    <PageAlikeModal {...innerPaperProps}>
      {withHeader && (
        <Typography
          display="flex"
          width="100%"
          variant="h4"
          alignItems="center"
          variantMapping={{ h4: 'h1' }}
          {...innerHeaderProps}
        >
          {innerHeaderProps.withBackButton && (
            <IconButton
              sx={{ position: 'absolute', left: 20, outline: 'none' }}
              {...(innerHeaderProps?.onBack
                ? { onClick: innerHeaderProps.onBack }
                : null)}
            >
              <Image
                src="/icons/back-black.svg"
                alt="Close"
                width={22}
                height={22}
                unoptimized
              />
            </IconButton>
          )}
          <Box m="auto" flexGrow={1} textAlign="center" paddingRight={5}>
            {innerHeaderProps.title}
          </Box>
        </Typography>
      )}
      {children}
    </PageAlikeModal>
  );
}

PageAlike.defaultProps = {
  paperProps: defaultPaperProps,
  headerProps: defaultHeaderProps,
  withHeader: true,
};
