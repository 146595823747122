import { styled, Theme } from '@mui/material';

export const Wrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(10, 0),
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(5),
  },
}));
