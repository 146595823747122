export const benefitsItems = [
  {
    icon: 'coach',
    title: 'One to One Coaching',
    body:
      'Experience the tailored expertise of our dedicated coaches, guiding you towards your wellness goals with personalized attention and care.',
  },
  {
    icon: 'group',
    title: 'Group Support',
    body:
      'Join a nurturing community where shared experiences foster understanding and growth, facilitated by skilled professionals committed to your well-being.',
  },
  {
    icon: 'seminars',
    title: 'Expert Seminars',
    body:
      'Immerse yourself in knowledge and practical wisdom shared by renowned experts, empowering you with insights and strategies for holistic wellness.',
  },
  {
    icon: 'navigation',
    title: 'Condition Specific Navigation',
    body:
      "Receive individualized support crafted for your unique health challenges, ensuring you're equipped to navigate your journey with confidence and clarity.",
  },
];
