import React from 'react';
import { Container } from '@/components/layout';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import Image from 'next/image';
import { Wrapper } from './ElevateEmployeeSection.styles';
import { benefitsItems } from './helpers';

const pl = 12.5;

const ElevateEmployeeSection = () => {
  const theme = useTheme();

  return (
    <Wrapper>
      <Container style={{ position: 'relative', zIndex: 3 }}>
        <Stack direction="row" flexWrap="wrap" gap={2}>
          <Stack
            position="relative"
            justifyContent="center"
            alignItems="center"
            flex={{ xs: '1 0 300px', sm: '1 0 400px' }}
          >
            <video
              controls={false}
              autoPlay
              playsInline
              webkit-playsInline
              muted
              loop
              width="100%"
              style={{ maxWidth: theme.spacing(136) }}
            >
              <source src="/images/home/ipad-demo.mp4" type="video/mp4" />
            </video>
          </Stack>
          <Stack
            flex={{ xs: '1 0 300px', sm: '1 0 400px' }}
            paddingY={{ xs: 0, lg: 6 }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: `${theme.spacing(9.5)} !important`,
                lineHeight: `1.25 !important`,
                color: theme.palette.primary.main,
                paddingLeft: { xs: 0, lg: theme.spacing(pl) },
              }}
            >
              Supercharge your Wellness Journey with Personalized Guidance
            </Typography>
            <Divider sx={{ margin: theme.spacing(4, 0) }} />
            {benefitsItems.map((el) => (
              <Stack
                key={el.icon}
                direction="row"
                sx={{ margin: theme.spacing(1, 0, 3) }}
              >
                <Image
                  src={`/images/home/benefits/${el.icon}.svg`}
                  layout="fixed"
                  width="36"
                  height="36"
                />
                <Box flex="1" sx={{ paddingLeft: theme.spacing(4) }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: `${theme.spacing(5.75)} !important`,
                      lineHeight: `1.4 !important`,
                      fontWeight: '700  !important',
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: theme.spacing(1),
                      fontSize: `${theme.spacing(3.5)} !important`,
                      lineHeight: `1.4 !important`,
                    }}
                  >
                    {el.body}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default ElevateEmployeeSection;
