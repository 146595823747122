import React, { useState } from 'react';
import { Typography, Stack, useTheme, Box, SxProps } from '@mui/material';
import { Coach } from '@/types/Content/Coaching';
import { stopPropagation } from '@/utils/common';
import Image from 'next/image';
import { opacityTransition } from './ConsultantsSection.styles';
import ConsultantMedia from './ConsultantMedia';

type Props = {
  coach: Coach;
  setSelectedCoach: (val: Coach) => void;
  sx?: SxProps;

  isDesktop: boolean;
};

const Consultant = ({ coach, setSelectedCoach, sx, isDesktop }: Props) => {
  const theme = useTheme();

  const selectCoach = (e) => {
    stopPropagation(e);
    setSelectedCoach(coach);
  };

  const [hovered, setHovered] = useState(false);

  return (
    <Stack
      alignItems="center"
      flexBasis={{ xs: theme.spacing(44), sm: 'unset' }}
      sx={sx}
      onMouseEnter={!isDesktop ? undefined : () => setHovered(true)}
      onMouseLeave={!isDesktop ? undefined : () => setHovered(false)}
    >
      <ConsultantMedia
        coach={coach}
        selectCoach={selectCoach}
        hovered={hovered}
        isDesktop={isDesktop}
      />

      <Stack
        alignItems="center"
        position="relative"
        zIndex={1}
        top="-6px"
        sx={{
          backgroundColor: '#fff',
          borderRadius: theme.spacing(1),
          padding: theme.spacing(2, 4),
          boxShadow: '-10px 4px 40px 0px #0000001A',
          cursor: 'pointer',
          transition: opacityTransition,
          opacity: hovered ? 0 : 1,
        }}
        onClick={selectCoach}
      >
        <Typography
          fontSize={`${theme.spacing(4.5)} !important`}
          lineHeight="1.45 !important"
          variant="h5"
          align="center"
        >
          {coach.fullName}
        </Typography>

        {coach.companyBage ? (
          <Box
            sx={{
              position: 'relative',
              width: theme.spacing(25),
              height: theme.spacing(7.5),
            }}
          >
            <Image
              src={coach.companyBage}
              layout="fill"
              objectFit="contain"
              objectPosition="center center"
            />
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );

  // return (
  //   <Grid item xs={12} lg={6} key={coach.slug}>
  //     <CoachContainer
  //       direction="column"
  //       onClick={selectCoach}
  //       colorTheme={cardColor}
  //     >
  //       <CoachBackImgWrapper colorTheme={cardColor}>
  //         <Icon
  //           iconName="coach-back-image"
  //           style={{ width: '100%', height: '100%' }}
  //         />
  //       </CoachBackImgWrapper>

  //       <StyledAvatar url={coach.homepageImage || coach.image} />

  //       <Stack direction="column" zIndex={1} paddingRight="35%" flex={1}>
  //         {coach.quote ? (
  //           <>
  //             <Typography
  //               color={getIntroColor(theme, cardColor)}
  //               fontSize={{ xs: 19, sm: 23 }}
  //               lineHeight="1.4 !important"
  //               variant="h3"
  //               marginBottom={{ xs: theme.spacing(3), sm: theme.spacing(6) }}
  //             >
  //               <Icon
  //                 iconName="quote-start"
  //                 style={{ color: getQuoteColor(theme, cardColor) }}
  //               />
  //               {'  '}
  //               {coach.quote}
  //               {'  '}
  //               <Icon
  //                 iconName="quote-end"
  //                 style={{ color: getQuoteColor(theme, cardColor) }}
  //               />
  //             </Typography>
  //             <div style={{ flex: 1 }} />
  //           </>
  //         ) : null}

  //         <Typography
  //           color={getNameColor(theme, cardColor)}
  //           fontSize={18}
  //           lineHeight="1.45 !important"
  //           variant="h5"
  //         >
  //           {coach.fullName}
  //         </Typography>
  //         <Typography
  //           lineHeight="1.3"
  //           variant="body2"
  //           color={getSubTitleColor(theme, cardColor)}
  //         >
  //           {coach.subTitle}
  //         </Typography>

  //         <div style={{ flex: 1 }} />
  //         <Stack
  //           direction="row"
  //           justifyContent="flex-start"
  //           alignItems="center"
  //           sx={{ pr: theme.spacing(2) }}
  //           marginTop={{ xs: theme.spacing(3), sm: theme.spacing(4) }}
  //           spacing={2}
  //         >
  //           <Button
  //             variant="contained"
  //             small
  //             onClick={selectCoach}
  //             sx={
  //               cardColor === CoachColorTheme.blue
  //                 ? {
  //                     backgroundColor: theme.palette.grey[50],
  //                     '&:hover': { backgroundColor: theme.palette.grey[100] },
  //                     color: theme.palette.grey[600],
  //                   }
  //                 : undefined
  //             }
  //           >
  //             More Info
  //           </Button>
  //         </Stack>
  //       </Stack>
  //     </CoachContainer>
  //   </Grid>
  // );
};

export default Consultant;
