import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import CoachingStore from '@/stores/CoachingStore';
import { Coach } from '@/types/Content/Coaching';
import { Container } from '@/components/layout';
import { observer } from 'mobx-react-lite';
import HomeTabConsultantModal from '@/domains/Village/Tabs/HomeTab/HomeTabConsultantModal';
import { useRouter } from 'next/router';
import { useResponsive } from '@/hooks/useResponsive';
import MainContentVideoModal from '@/domains/Village/Tabs/HomeTab/HomeTabVideoModal';
import { ConsultantsWrapper } from './ConsultantsSection.styles';
import Consultant from './Consultant';
import Background from './Background';
import { statItems } from './helpers';
import Stat from './Stat';

const ConsultantsSection = () => {
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();
  const router = useRouter();

  const [selectedCoach, setSelectedCoachDefault] = useState<Coach | null>(null);
  const setSelectedCoach = useCallback(
    (val: Coach | null) => {
      setSelectedCoachDefault(val);
      router.push(
        {
          hash: val ? '#consultants' : '',
          query: { ...router.query, coach: val?.slug },
        },
        undefined,
        { scroll: false },
      );
    },
    [router],
  );

  const closeModal = useCallback(() => setSelectedCoach(null), [
    setSelectedCoach,
  ]);

  const { coaches } = CoachingStore;
  const { coach: coachSlug } = router.query || {};
  useEffect(() => {
    CoachingStore.fetchCoaching({
      perPage: 10,
      homePageOnly: true,
      refresh: true,
    });
  }, []);

  useEffect(() => {
    if (coaches?.length && coachSlug) {
      const item = coaches.find((el) => el.slug === coachSlug);
      if (item) setSelectedCoachDefault(item);
    }
  }, [coachSlug, coaches, setSelectedCoach]);

  return (
    <ConsultantsWrapper>
      <Background />
      <Container
        style={{
          position: 'relative',
          zIndex: 3,
          padding: theme.spacing(10, 4),
        }}
        id="consultants"
      >
        <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
          <Stack flex="1 0 300px" sx={{ maxWidth: theme.spacing(131) }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  xs: `${theme.spacing(9.5)} !important`,
                  md: `${theme.spacing(12.5)} !important`,
                },
                lineHeight: `1.1 !important`,
                color: theme.palette.primary.main,
              }}
            >
              Meet Our Top-tier Field Specialists
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: theme.spacing(4),
                fontSize: `${theme.spacing(4.5)} !important`,
                lineHeight: `1.4 !important`,
              }}
            >
              Trust our dedicated team to navigate the complexities of
              healthcare, insurance, and overall well-being, ensuring you
              receive the support you need every step of the way.
            </Typography>
          </Stack>
          <Stack
            flex="1 0 300px"
            direction="row"
            flexWrap="wrap"
            justifyContent={{
              xs: 'center',
              sm: 'center',
              lg: 'space-between',
            }}
            alignItems="flex-start"
            gap={8}
            sx={{
              maxWidth: theme.spacing(160),
              marginTop: { xs: theme.spacing(11), sm: 0 },
            }}
          >
            {coaches?.slice(0, 2).map((coach, idx) => (
              <Consultant
                key={coach.slug}
                coach={coach}
                setSelectedCoach={setSelectedCoach}
                isDesktop={isDesktop}
                sx={
                  idx
                    ? {
                        marginTop: { xs: '0', lg: '65px' },
                        marginLeft: {
                          sm: theme.spacing(16),
                          md: 'auto',
                          lg: 0,
                        },
                      }
                    : {
                        marginLeft: { sm: theme.spacing(16) },
                      }
                }
              />
            ))}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent={{ xs: 'center', sm: 'space-evenly' }}
          alignItems="flex-start"
          gap={8}
          sx={{ marginTop: theme.spacing(10) }}
        >
          {coaches?.slice(2, 4).map((coach, idx) => (
            <Consultant
              key={coach.slug}
              coach={coach}
              setSelectedCoach={setSelectedCoach}
              isDesktop={isDesktop}
              sx={
                idx
                  ? undefined
                  : {
                      position: 'relative',
                      top: { md: '-40px', lg: '40px' },
                    }
              }
            />
          ))}
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          alignItems="flex-start"
          gap={8}
          sx={{
            marginTop: theme.spacing(10),
            marginBottom: { xs: theme.spacing(14), sm: theme.spacing(20) },
          }}
        >
          {coaches?.slice(4, 10).map((coach) => (
            <Consultant
              key={coach.slug}
              coach={coach}
              setSelectedCoach={setSelectedCoach}
              isDesktop={isDesktop}
            />
          ))}
        </Stack>
        <Stack sx={{ borderTop: '2px solid #B0B2BB' }}>
          <Typography
            variant="h2"
            sx={{
              margin: theme.spacing(7.5, 0),
              fontSize: {
                xs: `${theme.spacing(8)} !important`,
                md: `${theme.spacing(9.5)} !important`,
              },
              lineHeight: `1.25 !important`,
              color: theme.palette.grey[500],
            }}
          >
            Don’t Just Take Our Word for It...
          </Typography>

          <Stack direction="row" flexWrap="wrap" gap={5.75}>
            {statItems.map((el) => (
              <Stat key={el.image} item={el} />
            ))}
          </Stack>
        </Stack>
        {/* <Stack direction="column" spacing={6} marginTop={theme.spacing(6)}>
          <Box id="consultants">
            <Grid container spacing={6}>
              {coaches.length
                ? coaches.map((coach) =>
                    coach.homepageImage ? (
                      <Consultant
                        key={coach.slug}
                        coach={coach}
                        setSelectedCoach={setSelectedCoach}
                      />
                    ) : null,
                  )
                : CoachingStore.loading
                ? getCoachesSkeleton()
                : null}
            </Grid>
          </Box>
        </Stack> */}
        {selectedCoach?.longVideo ? (
          <MainContentVideoModal
            opened
            closeModal={closeModal}
            videoUrl={selectedCoach?.longVideo}
            title={selectedCoach?.fullName}
          />
        ) : (
          <HomeTabConsultantModal
            coachSlug={selectedCoach?.slug}
            closeModal={closeModal}
          />
        )}
      </Container>
    </ConsultantsWrapper>
  );
};

export default observer(ConsultantsSection);
