import { StyledComponent } from '@emotion/styled';
import { Paper, PaperProps, styled, Theme } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';
import { PaddingType, PaperPropTypes } from './PageAlike.types';

function getPadding({ padding, theme }: PaperPropTypes & { theme: Theme }) {
  if (Array.isArray(padding)) {
    return theme.spacing(...(padding as PaddingType));
  }
  if (typeof padding === 'number') {
    return theme.spacing(padding);
  }
  return theme.spacing(4);
}
export const PageAlikeModal: StyledComponent<
  PaperProps,
  MUIStyledCommonProps<Theme>,
  any
> = styled(Paper)`
  height: 100vh;
  width: 100vw;
  border-radius: 0;
  padding: ${({ theme }) => theme.spacing(4)};
  overflow: auto;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: min(761px, 100vh);
    width: min(544px, 100vw);
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: ${getPadding};
  }
`;
