export type TextEditorHTMLItemChild = {
  text: string;
  _key: string;
  marks: Array<'strong' | 'em' | string>;
};

export type TextEditorHTMLItem = {
  children: TextEditorHTMLItemChild[];
  _key: string;
  _type: 'block' | 'image';
  style?: 'normal' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'blockquote';
  markDefs?: Array<{
    _key: string;
    _type: 'color' | 'link';
    hex?: string;
    href?: string;
  }>;
  listItem?: 'bullet';
  asset?: {
    _ref: string;
    url?: string;
    _type: string;
  };
};

export enum CoachColorTheme {
  white = 'white',
  yellow = 'yellow',
  blue = 'blue',
}

export type Coach = {
  slug: string;
  fullName: string;
  subTitle: string;
  image: string;
  homepageImage?: string;
  calendlyLink?: string;
  chatInitMessage?: string;
  specialties: string[];
  quote?: string;
  cardColor?: CoachColorTheme;
  bio:
    | string
    | {
        all: TextEditorHTMLItem[];
      };
  shortVideo?: string;
  longVideo?: string;
  companyBage?: string;
  calendlyEventPrice?: number;
  metaTitle?: string | null;
  metaDescription?: string | null;
  thumbnail?: string | null;
  landingTitle?: string | null;
  landingSubtitle?: string | null;
  landingLogos?: string[] | null;
  landingBullets?: string[] | null;
  categories?: CoachCategory[];
};

export type ConsultantsCategoriesInfo = Record<string, boolean>;

export type CoachCategory = {
  id: string;
  slug: string;
  title: string;
};

export type CoachFeedback = {
  created_at: string;
  rating: number;
  title: string;
  comment: string;
};
