import { makeAutoObservable } from 'mobx';
import { THomeContent } from '@/types/Content/Home';
import homeService from '@/services/homeService';
import { Resource } from '@/types/Content/Resources';

class HomeStore {
  private _homeContent: THomeContent | null = null;

  private _stories: Resource[] | null = null;

  private _loading = false;

  private _lastCacheDate = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get homeContent(): THomeContent | null {
    return this._homeContent;
  }

  get stories(): Resource[] | null {
    return this._stories;
  }

  get loading(): boolean {
    return this._loading;
  }

  private setHomeContent(content?: THomeContent) {
    if (content) {
      this._lastCacheDate = Date.now();
      this._homeContent = content;
    }
  }

  private setStories(stories?: Resource[]) {
    if (stories) {
      this._lastCacheDate = Date.now();
      this._stories = stories;
    }
  }

  async getHomeContent() {
    if (!this._loading && Date.now() - this._lastCacheDate <= 5 * 60 * 1000) {
      this._loading = true;
      const content = await homeService.getHomeContent();
      if (content) {
        this.setHomeContent(content);
      }
      this._loading = false;
    }
  }

  hydrate({
    content,
    stories,
  }: {
    content: THomeContent | null;
    stories: Resource[] | null;
  }) {
    this.setHomeContent(content);
    this.setStories(stories);
  }

  reset() {
    this._homeContent = null;
    this._loading = false;
    this._lastCacheDate = 0;
  }
}

export default new HomeStore();
