import React from 'react';
import xw from 'xwind';
import { Box } from '@mui/material';
import Modal from '@/widgets/Modal';
import { YouTubeVideoPlayer } from '@/components/YouTubeVideoPlayer';
import { stopPropagation } from '@/utils/common';

import { VideoModalWrapper } from './HomeTab.styles';

type Props = {
  youtubeVideoId?: string;
  videoUrl?: string;
  title: string;
  opened: boolean;
  closeModal: () => void;
};

const MainContentVideoModal = ({
  youtubeVideoId,
  videoUrl,
  title,
  opened,
  closeModal,
}: Props) => {
  return (
    <Modal
      isOpen={!!(youtubeVideoId || videoUrl) && opened}
      handleClose={closeModal}
      closeOnBackClick
      useOutsideCloseButton
      insideCloseButtonForSmScreen={false}
      overlayStyle={{ marginTop: 0 }}
    >
      <VideoModalWrapper onClick={stopPropagation} onScroll={stopPropagation}>
        <Box sx={xw`absolute inset-0`}>
          {videoUrl ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls autoPlay height="100%" width="100%">
              <source src={videoUrl} type="video/mp4" />
            </video>
          ) : youtubeVideoId ? (
            <YouTubeVideoPlayer
              videoId={youtubeVideoId}
              height="100%"
              width="100%"
              title={title}
            />
          ) : null}
        </Box>
      </VideoModalWrapper>
    </Modal>
  );
};

export default MainContentVideoModal;
