import { IVideoPlayerProps } from '@/types/VideoPlayerProps';

export function YouTubeVideoPlayer({
  width,
  title,
  height,
  videoId,
  className,
  controls = 1,
}: IVideoPlayerProps): JSX.Element {
  return (
    <iframe
      id="ytplayer"
      title={title}
      className={className}
      width={width}
      height={height}
      src={
        videoId
          ? `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=${controls}&origin=http://example.com`
          : undefined
      }
      frameBorder="0"
    />
  );
}
