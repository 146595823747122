import React from 'react';
import { styled, SxProps, Theme, Typography } from '@mui/material';

export const headerH = 100;
export const HeaderWrapper = styled('div')<{
  sticky: boolean;
  headerScrolledOut: boolean;
  animEnabled: boolean;
}>(({ theme, sticky, headerScrolledOut, animEnabled }) => ({
  ...(sticky
    ? {
        backgroundColor: '#ffffffAA',
        backdropFilter: 'blur(10px)',
        borderBottom: `1px solid ${theme.palette.grey[150]}`,
      }
    : { backgroundColor: 'transparent' }),
  padding: theme.spacing(0),
  width: '100%',
  zIndex: 10,

  ...(animEnabled
    ? { transitionProperty: 'top, background-color', transitionDuration: '.5s' }
    : null),

  ...(headerScrolledOut
    ? { position: 'fixed', top: '-150px' }
    : { position: 'absolute', top: 0 }),

  ...(sticky ? { top: '-1.5rem' } : null),
}));
export const HeroWrapper = styled('div')<{ videoActive: boolean }>(
  ({ theme }) => ({
    backgroundColor: '#569ea6',
    backgroundImage: 'url(/images/home/noise.svg)',
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'repeat',
    backgroundSize: '400px',
    padding: theme.spacing(0),
    position: 'relative',
    overflow: 'hidden',
  }),
);

export const HeroHeader = styled((props: any) => (
  <Typography variant="h1" {...props} />
))`
  font-size: ${({ theme }) => theme.spacing(9)} !important;
  line-height: 1.1 !important;
  letter-spacing: 0.2px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.spacing(8)} !important;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.spacing(9)} !important;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: ${({ theme }) => theme.spacing(12.5)} !important;
  }
`;

export const LogoWrapper = styled('div')<{ collapsed?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ collapsed = false }) => (collapsed ? 0 : 1)};
  transition: width 0.2s ease-in-out, height 0.2s 0.1s ease-out,
    opacity 0.5s linear;
  width: ${({ theme }) => theme.spacing(8)};
  min-width: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.spacing(8)};
  align-self: center;
  margin: ${({ theme }) => theme.spacing(1, 0)};
  max-width: calc(100% - 170px);

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ theme }) => theme.spacing(42)};
    min-width: ${({ theme }) => theme.spacing(42)};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: none;
    flex: 0 1 auto;
    opacity: 1;
    width: ${({ theme }) => theme.spacing(48)};
    height: ${({ theme }) => theme.spacing(10)};
  }
`;

export const transformTransition = 'transform .5s ease-in-out';
export const translateXRight = 'translateX(60vw)';

export const getRightTransition = (move: boolean): SxProps => ({
  transition: transformTransition,
  transform: move
    ? { xs: 'translateX(100vw)', sm: 'translateX(60vw)' }
    : undefined,
});

export const getLeftTransition = (move: boolean): SxProps => ({
  transition: transformTransition,
  transform: move
    ? { xs: 'translateX(-100vw)', sm: 'translateX(-60vw)' }
    : undefined,
});
