import React from 'react';
import Image from 'next/image';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { StatItem } from './helpers';

type Props = {
  item: StatItem;
};

const Stat = ({ item }: Props) => {
  const theme = useTheme();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      flexWrap="wrap"
      flex={{ xs: '1 0 300px', sm: '1 0 380px' }}
      sx={{
        borderRadius: theme.spacing(4),
        overflow: 'hidden',
        boxShadow: '7px 8px 30px 0px #00000014',
        border: '1px solid #00000011',
      }}
    >
      <Stack
        flex={{ xs: 'unset', sm: '2 0 200px' }}
        sx={{
          backgroundColor: theme.palette.grey[50],
          padding: theme.spacing(4, 6, 5),
        }}
        justifyContent="flex-start"
      >
        <Stack
          direction="row"
          alignItems="center"
          position="relative"
          height={item.imageHeight ?? '24px'}
          minHeight="60px"
          width={item.imageWidth ?? '93px'}
        >
          <Image
            src={`/images/home/${item.image}`}
            layout="fill"
            objectPosition="left center"
          />
        </Stack>
        <Typography
          variant="body2"
          sx={{
            marginTop: theme.spacing(1),
            fontSize: `${theme.spacing(3)} !important`,
            lineHeight: `1.5 !important`,
          }}
        >
          {item.description}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{ marginTop: theme.spacing(2.5) }}
          columnGap={2}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: `${theme.spacing(3.5)} !important`,
              fontWeight: '700',
              lineHeight: `1.3 !important`,
              color: theme.palette.primary[700],
            }}
          >
            {item.user.name}
          </Typography>
          <Box
            sx={{
              width: theme.spacing(2),
              height: theme.spacing(2),
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.grey[200],
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: `${theme.spacing(3.5)} !important`,
              fontWeight: '700',
              lineHeight: `1.3 !important`,
              color: theme.palette.primary.main,
            }}
          >
            {item.user.position}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        flex={{ xs: 'unset', sm: '1 0 180px' }}
        sx={{
          backgroundColor: '#717792',
          padding: {
            xs: theme.spacing(4, 6),
            sm: theme.spacing(4, 4),
            lg: theme.spacing(0, 6),
          },
        }}
        flexDirection={{ xs: 'row', sm: 'column' }}
        // alignItems={{ xs: 'flex-start', sm: 'unset' }}
        justifyContent="center"
        flexWrap="nowrap"
      >
        {item.stats.map((el, idx) => (
          <Box
            key={el.key}
            sx={{
              color: '#fff',
              borderTop: {
                sm: idx ? '2px solid #fff' : undefined,
              },
              borderLeft: {
                xs: idx ? '2px solid #fff' : undefined,
                sm: 'unset',
              },
              padding: {
                xs: idx ? theme.spacing(0, 0, 0, 2) : theme.spacing(0, 2, 0, 0),
                sm: theme.spacing(2, 0),
              },
              flex: { xs: '1 0', sm: 'unset' },
            }}
          >
            <Stack direction="row" flexWrap="nowrap" alignItems="center">
              <Typography
                variant="h5"
                sx={{
                  fontSize: `${theme.spacing(8)} !important`,
                  lineHeight: `1.23 !important`,
                }}
              >
                {el.val}
              </Typography>
              {el.image ? (
                <Box
                  flex={1}
                  sx={{
                    marginLeft: {
                      xs: theme.spacing(2),
                      sm: theme.spacing(3.5),
                    },
                  }}
                >
                  <Box
                    position="relative"
                    height={theme.spacing(4)}
                    maxWidth={theme.spacing(24)}
                    width="100%"
                  >
                    <Image
                      src={`/images/home/${el.image}`}
                      layout="fill"
                      objectPosition="left center"
                    />
                  </Box>
                  {el.imageNote ? (
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: `${theme.spacing(2)} !important`,
                        lineHeight: `1.4 !important`,
                        fontWeight: '900  !important',
                        marginTop: theme.spacing(1),
                        marginBottom: 0,
                        textTransform: 'uppercase',
                      }}
                    >
                      {el.imageNote}
                    </Typography>
                  ) : null}
                </Box>
              ) : null}
            </Stack>
            <Typography
              variant="body2"
              sx={{
                fontSize: `${theme.spacing(3)} !important`,
                lineHeight: `1.5 !important`,
                marginBottom: 0,
              }}
            >
              {el.descr}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default Stat;
