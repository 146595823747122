import { styled, Typography } from '@mui/material';

export const HomeH2: typeof Typography = styled((props) => (
  <Typography align="center" variant="h2" {...props} />
))`
  font-weight: 700;
  font-size: ${({ theme }) => theme.spacing(7)};
  color: ${({ theme }) => theme.palette.grey[900]};
  line-height: 1;
  letter-spacing: 0.2px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.spacing(9)};
  }
`;
