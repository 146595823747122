import { Button, Stack, styled } from '@mui/material';
import NextImage from 'next/image';

export const TestimonialsWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(15, 0, 11)};
  overflow: hidden;

  background-color: #fff4da;
  background: center/cover url(images/home/testimonials-background.png)
    no-repeat;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(16, 0, 19)};
  }
`;

export const VideoImageWrapper = styled(Stack)`
  width: ${({ theme }) => theme.spacing(94)};
  height: ${({ theme }) => theme.spacing(58)};
  overflow: hidden;
  position: relative;
  border-radius: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
`;

export const VideoImage = styled(NextImage)`
  opacity: 0.8;
  background-color: ${({ theme }) => theme.palette.grey[500]};
`;

export const TestimonialsSlider = styled('div')`
  padding: ${({ theme }) => theme.spacing(11, 0, 0)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(17.5, 0, 0)};
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(2)};
    font-size: 0;
    color: transparent;
    padding-top: ${({ theme }) => theme.spacing(5.5)};
    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-top: ${({ theme }) => theme.spacing(12)};
      gap: ${({ theme }) => theme.spacing(6)};
    }

    li {
      button {
        background-color: #e5edf8;
        width: ${({ theme }) => theme.spacing(3)};
        height: ${({ theme }) => theme.spacing(1)};
        border-radius: 9999px;
        transition: color 0.2s;

        &:hover {
          cursor: pointer;
        }
        ${({ theme }) => theme.breakpoints.up('md')} {
          width: ${({ theme }) => theme.spacing(12)};
          height: ${({ theme }) => theme.spacing(1.5)};
        }
      }

      &.slick-active {
        button {
          background-color: ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }
  }

  .testimonial-item {
    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding: ${({ theme }) => theme.spacing(0, 2)};
    }
  }

  .slick-list {
    ${({ theme }) => theme.breakpoints.up('lg')} {
      margin: ${({ theme }) => theme.spacing(0, -3)};
      overflow: visible;
    }
  }
`;

export const TestimonialsSwiperNav: typeof Stack = styled((props) => (
  <Stack direction="row" spacing={6.25} {...props} />
))`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: flex;
  }
`;

export const TestimonialsSwiperNavBtn: typeof Button = styled((props) => (
  <Button variant="outlined" {...props} />
))`
  font-size: ${({ theme }) => theme.spacing(3.5)};
  border-radius: 9999px;
  padding: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;
