import { Stack, SxProps } from '@mui/material';
import React, { useState } from 'react';
import { getRandomInt } from '@/utils/common';
import { CircleData, circle1Data, circle2Data, circle3Data } from './helpers';
import { CircleContainer, CircleItemContainer } from './Background.styles';

const CircleItem = ({
  item,
  scaleKey,
  animate,
}: {
  item: CircleData;
  scaleKey: string;
  animate: boolean;
}) => {
  const [timing] = useState(getRandomInt(15, 50));

  return (
    <CircleItemContainer
      timing={timing}
      scaleKey={scaleKey}
      animate={animate}
      sx={{
        position: 'absolute',
        width: item.size,
        height: item.size,
        borderRadius: '50%',
        border: item.border || 'unset',
        backgroundColor: item.backgroundColor || 'transparent',
      }}
    />
  );
};
export const renderCircle = (
  data: CircleData[],
  sx?: SxProps,
  {
    animate = true,
    maxScale = 1.2,
  }: { animate?: boolean; maxScale?: number } = {},
) => {
  const firstItem = data[0];
  const scaleKey = `${maxScale}`.replace('.', '');

  return (
    <CircleContainer
      maxScale={maxScale}
      scaleKey={scaleKey}
      sx={{
        position: 'absolute',
        width: firstItem.size,
        height: firstItem.size,
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {data.map((el, idx) => (
        <CircleItem
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          item={el}
          scaleKey={scaleKey}
          animate={animate}
        />
      ))}
    </CircleContainer>
  );
};

const Background = () => {
  return (
    <Stack
      style={{
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      {renderCircle(circle1Data, {
        top: -circle1Data[0].size * 0.43,
        marginLeft: { xs: '10rem', sm: '15rem', lg: '25rem' },
        transform: { xs: 'scale(0.6)', sm: 'scale(0.8)', lg: 'unset' },
      })}
      {renderCircle(
        circle2Data,
        {
          left: {
            xs: -circle2Data[0].size * 0.4,
            xl: -circle2Data[0].size * 0.3,
          },
          marginTop: { xs: '0', sm: '2rem', lg: '8rem' },
          transform: {
            xs: 'scale(0.6)',
            sm: 'scale(0.7)',
            lg: 'scale(0.8)',
            xl: 'unset',
          },
        },
        { maxScale: 1.1 },
      )}
      {renderCircle(
        circle3Data,
        {
          bottom: -circle3Data[0].size * 0.45,
          right: {
            xs: -circle3Data[0].size * 0.4,
            md: -circle3Data[0].size * 0.3,
          },
          transform: {
            xs: 'scale(0.6)',
            md: 'scale(0.65)',
            lg: 'scale(0.9)',
            xl: 'unset',
          },
        },
        { maxScale: 1 },
      )}
    </Stack>
  );
};

export default Background;
