import { Box, Stack, styled } from '@mui/material';

export const CircleContainer = styled(Stack)<{
  maxScale: number;
  scaleKey: string;
}>`
  @keyframes scaleCircle${({ scaleKey }) => scaleKey} {
    0% {
      transform: scale(${({ maxScale }) => maxScale});
    }
    50% {
      transform: scale(${({ maxScale }) => maxScale * 0.5});
    }
    100% {
      transform: scale(${({ maxScale }) => maxScale});
    }
  }
`;

export const CircleItemContainer = styled(Box)<{
  scaleKey: string;
  timing: number;
  animate: boolean;
}>`
  animation: ${({ animate, scaleKey, timing }) =>
    animate ? `scaleCircle${scaleKey} ${timing}s linear infinite` : 'unset'};
`;
