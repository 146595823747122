import React from 'react';
import { PageLayout } from '@/widgets/index';
import HeroSection from '@/domains/NewHome/components/HeroSection/HeroSection';
import TestimonialsSection from '@/domains/NewHome/components/TestimonialsSection/TestimonialsSection';
import ConsultantsSection from '@/domains/NewHome/components/ConsultantsSection/ConsultantsSection';

import 'swiper/swiper.min.css';
import 'slick-carousel/slick/slick.css';
import ElevateEmployeeSection from '../components/ElevateEmployeeSection/ElevateEmployeeSection';
import Footer from '../components/Footer/Footer';
import Header from '../components/HeroSection/Header';

const UnauthorizedHomePage: React.FC = () => {
  return (
    <PageLayout header={<Header />} footer={<Footer />} showMobTabBar={false}>
      <HeroSection />
      <ElevateEmployeeSection />
      <ConsultantsSection />
      <TestimonialsSection />

      {/* <InfoSection />
      <HowWorksSection />
      <StoriesSection /> */}
    </PageLayout>
  );
};

export default UnauthorizedHomePage;
