export type CircleData = {
  size: number;
  border?: string;
  backgroundColor?: string;
};

export const circle1Data: CircleData[] = [
  { size: 950, border: '2px solid #EEF6F6' },
  { size: 885, backgroundColor: '#EEF6F6' },
  { size: 845, backgroundColor: '#d5ebeb' },
  { size: 400, backgroundColor: '#EEF6F6' },
  { size: 307, backgroundColor: '#B7DEDE' },
];
export const circle2Data: CircleData[] = [
  { size: 788, border: '3px solid #FFF4DA' },
  { size: 759, backgroundColor: '#FFE6A4' },
  { size: 534, backgroundColor: '#FFD25C' },
];
export const circle3Data: CircleData[] = [
  { size: 1165, backgroundColor: '#E7E9EF' },
  { size: 1037, backgroundColor: '#C0C4D1' },
];

export type StatItem = {
  image: string;
  imageWidth?: string;
  imageHeight?: string;
  description: string;
  stats: Array<{
    key: string;
    val: string;
    descr: string;
    image?: string;
    imageNote?: string;
  }>;
  user: {
    name: string;
    position: string;
  };
};
export const statItems: StatItem[] = [
  {
    image: 'clutch.svg',
    description:
      'I feel great knowing that our team members are in such good hands with OneVillage. It takes a tremendous burden off of my HR team knowing that our team has the necessary support for when things go unexpectedly.',
    stats: [
      {
        key: '1',
        val: '98%',
        descr: 'of members are more positive after their session',
      },
      {
        key: '2',
        val: '20%',
        descr:
          'On average, we reduce members stress by 20% after just 4 weeks without any medical intervention',
      },
    ],
    user: { name: 'Lindsy Noble', position: 'Chief People Officer' },
  },
  {
    image: 'custom-ink.svg',
    imageWidth: '108px',
    imageHeight: '40px',
    description:
      'OneVillage has been a game changer with specialized coaching that tackles the root of mental health issues. They enrich our employees wellbeing, and also streamline our HR process by directing members to the exact care they need.',
    stats: [
      {
        key: '1',
        val: '95%',
        descr:
          'of members report learning something in their session that will improve their health and well-being',
      },
      {
        key: '2',
        val: '4.9',
        image: 'stars.svg',
        imageNote: 'overall star rating',
        descr:
          'The average member at Custom Ink rates their session 4.9 of 5.0 stars',
      },
    ],
    user: { name: 'Katie Sue', position: 'Benefits Manager' },
  },
];
