import { Coach } from '@/types/Content/Coaching';
import React, { useEffect, useRef, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import Icon from '@/components/icons/Icon';
import {
  StyledAvatar,
  StyledBorderCircle,
  StyledMediaContainer,
  StyledVideo,
  opacityTransition,
  rotateCircle,
  size,
} from './ConsultantsSection.styles';

type Props = {
  coach: Coach;
  selectCoach: any;
  hovered: boolean;
  isDesktop: boolean;
};

const ConsultantMedia = ({ coach, selectCoach, hovered, isDesktop }: Props) => {
  const theme = useTheme();
  const [showVideo, setShowVideo] = useState(false);
  const [videoHovered, setVideoHovered] = useState(false);

  const timeoutRef = useRef<any>(null);
  useEffect(() => {
    if (!isDesktop) return;
    clearTimeout(timeoutRef.current);
    if (hovered) {
      setShowVideo(true);
      timeoutRef.current = setTimeout(() => setVideoHovered(true), 50);
    } else {
      setVideoHovered(false);
      timeoutRef.current = setTimeout(() => setShowVideo(false), 300);
    }
  }, [hovered, isDesktop]);

  const { homepageImage, image, shortVideo, longVideo } = coach;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
        zIndex: 2,
      }}
    >
      <StyledMediaContainer hovered={hovered} onClick={selectCoach}>
        <StyledAvatar
          url={image || homepageImage}
          style={{
            cursor: 'pointer',
          }}
          hovered={Boolean(hovered && shortVideo)}
        />
        {showVideo && shortVideo ? (
          <StyledVideo
            controls={false}
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            hovered={videoHovered}
          >
            <source src={shortVideo} type="video/mp4" />
          </StyledVideo>
        ) : null}
        {showVideo && longVideo ? (
          <Stack
            direction="row"
            justifyContent="center"
            gap={2}
            sx={{
              color: theme.palette.grey[50],
              paddingBottom: theme.spacing(7),
              position: 'absolute',
              bottom: 0,
              width: '100%',
              zIndex: 1,
              transition: opacityTransition,
              opacity: videoHovered ? 1 : 0,
            }}
          >
            <Icon iconName="play" style={{ fontSize: '24px' }} />
            <Typography
              variant="body2"
              fontSize={`${theme.spacing(4.5)} !important`}
              lineHeight="1.45 !important"
              fontWeight="700"
            >
              Play
            </Typography>
          </Stack>
        ) : null}
        <StyledBorderCircle
          hovered={hovered}
          sx={{
            animation: hovered
              ? `${rotateCircle} 30s linear infinite`
              : 'unset',
          }}
        />
      </StyledMediaContainer>
    </Stack>
  );
};

export default ConsultantMedia;
