import { CalendlyEmbeded } from '@/components/CalendlyEmbeded';
import PageFooter from '@/widgets/PageFooter';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import Image from 'next/image';
import { Button } from '@/components/Button/Button';
import AuthModalStore from '@/stores/AuthModalStore';

const Footer = ({
  title = 'See OneVillage Solutions in Action',
  subTitle = "Learn more about how we can partner to build an engaged population and achieve your organization's strategic objectives.",
  getStarted = false,
}: {
  title?: string;
  subTitle?: string;
  getStarted?: boolean;
}) => {
  const theme = useTheme();
  const buttonSx = {
    color: theme.palette.grey[900],
    padding: theme.spacing(3, 9),
    marginTop: theme.spacing(11),
    marginX: { xs: 'auto', sm: theme.spacing(0) },
  };

  const ageTechSize: any = {
    xs: theme.spacing(48),
    sm: theme.spacing(48),
    md: theme.spacing(36),
    lg: theme.spacing(42),
    xl: theme.spacing(48),
  };

  return (
    <PageFooter
      bottomChild={
        <Box
          sx={{
            marginBottom: { xs: theme.spacing(16), md: theme.spacing(22) },
          }}
        />
      }
    >
      <Box>
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          gap={{ xs: 5, lg: 10, xl: 15 }}
          marginTop={{ xs: theme.spacing(10), md: theme.spacing(20) }}
        >
          <Stack
            flex="1 0 300px"
            position="relative"
            paddingRight={{ xs: theme.spacing(2) }}
            gap={theme.spacing(6)}
            flexDirection="column"
            flexWrap="nowrap"
            alignItems="center"
          >
            <Box
              sx={{
                position: { xs: 'relative', sm: 'absolute' },
                zIndex: 1,
                height: ageTechSize,
                width: ageTechSize,
                bottom: {
                  xs: 'unset',
                  sm: theme.spacing(7),
                  md: theme.spacing(0),
                  lg: theme.spacing(7),
                },
                right: { xs: 'unset', sm: 0 },
              }}
            >
              <Image
                src="/images/home/age-tech.png"
                layout="fill"
                objectFit="contain"
                objectPosition="center top"
              />
            </Box>
            <Box
              position="relative"
              sx={{ paddingBottom: '63%', width: '100%' }}
            >
              <Image
                src="/images/home/Apps.png"
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            </Box>
          </Stack>

          <Stack
            flex="1 0 300px"
            alignItems="flex-start"
            sx={{ color: theme.palette.grey[50] }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  xs: `${theme.spacing(9.5)} !important`,
                  md: `${theme.spacing(11)} !important`,
                  lg: `${theme.spacing(12.5)} !important`,
                },
                lineHeight: `1.1 !important`,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: theme.spacing(4),
                fontSize: {
                  xs: `${theme.spacing(4)} !important`,
                  lg: `${theme.spacing(4.5)} !important`,
                },
                lineHeight: `1.4 !important`,
              }}
            >
              {subTitle}
            </Typography>
            {getStarted ? (
              <Button
                color="secondary"
                variant="contained"
                sx={buttonSx}
                onClick={() => AuthModalStore.showModal('signup')}
              >
                Get Started
              </Button>
            ) : (
              <CalendlyEmbeded color="secondary" sx={buttonSx}>
                Book a Demo
              </CalendlyEmbeded>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            width: '100%',
            borderTop: '1px solid #D5ECEC',
            margin: theme.spacing(11, 0),
          }}
        />
      </Box>
    </PageFooter>
  );
};

export default Footer;
