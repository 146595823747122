import React from 'react';
import { observer } from 'mobx-react-lite';

import UnauthorizedHomePage from '@/domains/NewHome/pages/UnauthorizedHomePage';
import { useRedirectToLoggedIn } from '@/hooks/useRedirectToLoggedIn';

const NewHome: React.FC = () => {
  useRedirectToLoggedIn();

  return <UnauthorizedHomePage />;
};

export default observer(NewHome);
