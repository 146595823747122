import { cmsProxyApi } from '@/services/cmsProxyApi';
import * as Sentry from '@sentry/nextjs';
import { THomeContent } from '@/types/Content/Home';

const homeService = {
  async getHomeContent(): Promise<THomeContent | null> {
    try {
      const { data } = await cmsProxyApi.get<THomeContent>('/homepage');
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default homeService;
