import React from 'react';

import HomeStore from '@/stores/HomeStore';
import { THomeContent } from '@/types/Content/Home';
import NewHome from '@/domains/NewHome';
import homeService from '@/services/homeService';
import { GetStaticPropsResult } from 'next';
import { cmsProxyApi } from '@/services/cmsProxyApi';
import { Resource } from '@/types/Content/Resources';
import { HTTPListResponse } from '@/types/HTTPListResponse';

interface IHome {
  content: THomeContent | null;
  stories: Resource[] | null;
}

const Home: React.FC<IHome> = ({ content, stories }) => {
  HomeStore.hydrate({ content, stories });

  return <NewHome />;
};

export default Home;

export async function getStaticProps(): Promise<GetStaticPropsResult<IHome>> {
  const promises = [
    homeService.getHomeContent(),
    cmsProxyApi.get<HTTPListResponse<Resource>>('/resources', {
      params: { tags: ['rls-homepage-featured'], page: 0 },
    }),
  ];

  const results = await Promise.allSettled(promises);

  const [content, storiesResults] = results.map((promise) => {
    if (promise.status === 'rejected') {
      return null;
    }
    return promise.value;
  });

  return {
    props: {
      content: content as THomeContent,
      // TODO Promise.allSettled typification
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      stories: (storiesResults?.data?.results || []) as Resource[],
    },
    revalidate: 60,
  };
}
