import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Stack, useTheme } from '@mui/material';
import Image from 'next/image';
import HomeStore from '@/stores/HomeStore';
import {
  HeroWrapper,
  getRightTransition,
} from '@/domains/NewHome/components/HeroSection/HeroSection.styles';
import MainContentVideoModal from '@/domains/Village/Tabs/HomeTab/HomeTabVideoModal';
import { useResponsive } from '@/hooks/useResponsive';
import { Container } from '@/components/layout';
import ModalNewCloseButton from '@/widgets/Modal/ModalNewCloseButton/ModalNewCloseButton';
import Background from './Background';
import HeroLeftContent from './HeroLeftContent';

const HeroSection: React.FC = () => {
  const theme = useTheme();
  const { homeContent } = HomeStore;
  const isMobile = useResponsive('down', 'md');

  // const [showVideo, setVideoOpened] = useState(false);
  const [videoOpened, setVideoOpened] = useState(false);
  const closeVideoModal = useCallback(() => setVideoOpened(false), []);

  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    if (!videoOpened) {
      setVideoVisible(false);
      return;
    }

    if (isMobile) setVideoVisible(true);
    setTimeout(() => setVideoVisible(true), 400);
  }, [isMobile, videoOpened]);

  const ageTechSize: any = {
    xs: theme.spacing(40),
    lg: theme.spacing(50),
  };

  const showDesktopVideo = videoOpened && !isMobile;
  return (
    <HeroWrapper videoActive={showDesktopVideo}>
      <Background moveRight={showDesktopVideo} />
      <Container
        style={{
          position: 'relative',
          zIndex: 3,
        }}
      >
        <Stack
          direction="column"
          flexWrap="nowrap"
          columnGap={theme.spacing(4)}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            flexWrap="wrap"
            position="relative"
            justifyContent="center"
            sx={{
              marginTop: {
                xs: theme.spacing(23),
                md: theme.spacing(25),
              },
            }}
          >
            <HeroLeftContent
              moveLeft={showDesktopVideo}
              setVideoOpened={setVideoOpened}
            />

            <Stack
              position="relative"
              flex={{ xs: 'unset', sm: '1' }}
              minHeight={{
                xs: theme.spacing(85),
                sm: 'unset',
                lg: theme.spacing(130),
                xl: theme.spacing(150),
              }}
              sx={{
                zIndex: 1,
                ...(isMobile ? undefined : getRightTransition(videoOpened)),
              }}
            >
              <Image
                src="/images/home/hero/DrLeslieWaltke.png"
                layout="fill"
                objectFit="contain"
                objectPosition={isMobile ? 'bottom center' : 'bottom right'}
              />
            </Stack>

            <Stack
              position="absolute"
              sx={{
                top: 0,
                bottom: 0,
                width: '100%',
                maxWidth: {
                  sm: theme.spacing(143.5),
                  md: theme.spacing(185),
                  lg: theme.spacing(231),
                  xl: theme.spacing(266.75),
                },
                zIndex: 0,
                transition: 'opacity 1s',
                opacity: showDesktopVideo ? 1 : 0,
              }}
            >
              {showDesktopVideo &&
              videoVisible &&
              homeContent?.headerVideoFile ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  controls
                  autoPlay
                  height="100%"
                  width="100%"
                  style={{ minHeight: '100%' }}
                >
                  <source src={homeContent?.headerVideoFile} type="video/mp4" />
                </video>
              ) : null}
              {showDesktopVideo && (
                <ModalNewCloseButton
                  outsidePosition
                  insideForSmScreen={false}
                  onClick={closeVideoModal}
                  sx={{ position: 'absolute', right: '-3rem', top: '0rem' }}
                />
              )}
            </Stack>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 2,
                height: ageTechSize,
                width: ageTechSize,
                bottom: { xs: theme.spacing(3), lg: theme.spacing(12) },
                right: { xs: theme.spacing(3), lg: theme.spacing(10) },
                ...(isMobile ? undefined : getRightTransition(videoOpened)),
              }}
            >
              <Image
                src="/images/home/age-tech.png"
                layout="fill"
                objectFit="contain"
                objectPosition="center top"
              />
            </Box>
          </Stack>
        </Stack>
      </Container>
      <MainContentVideoModal
        opened={videoOpened && isMobile}
        closeModal={closeVideoModal}
        videoUrl={homeContent?.headerVideoFile}
        youtubeVideoId={homeContent?.headerVideo.youtubeVideoId}
        title={homeContent?.headerVideo.title}
      />
    </HeroWrapper>
  );
};

export default observer(HeroSection);
