import React, { useMemo } from 'react';
import { SxProps, useMediaQuery, useTheme } from '@mui/material';

import Icon from '@/components/icons/Icon';
import { CloseButton } from '../Modal.styles';

type Props = {
  outsidePosition?: boolean;
  withBg?: boolean;
  insideForSmScreen?: boolean;
  sx: SxProps;
  onClick: () => void;
};

const ModalNewCloseButton = ({
  outsidePosition = false,
  withBg = outsidePosition,
  insideForSmScreen = true,
  onClick,
  sx,
}: Props) => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(`${theme.breakpoints.up('sm')}`);

  const bg = useMemo(
    () =>
      withBg
        ? {
            backgroundColor: theme.palette.grey[100],
            fontSize: '0.8rem',
            color: theme.palette.grey[500],
            padding: theme.spacing(3),
            '&:hover': {
              backgroundColor: theme.palette.grey[50],
            },
          }
        : null,
    [withBg, theme],
  );

  const outsidePositionSx = useMemo(
    () =>
      outsidePosition
        ? {
            top: matchesSmUp ? 0 : insideForSmScreen ? '1rem' : '-3rem',
            right: matchesSmUp ? '-3rem' : insideForSmScreen ? '1rem' : 0,
          }
        : null,
    [matchesSmUp, outsidePosition, insideForSmScreen],
  );

  return (
    <CloseButton onClick={onClick} sx={{ ...bg, ...outsidePositionSx, ...sx }}>
      <Icon iconName="cross" />
    </CloseButton>
  );
};

export default ModalNewCloseButton;
