import React, { useState } from 'react';
import xw from 'xwind';
import Image from 'next/image';

import { Container } from '@/components/layout';
import { TestimonialsWrapper } from '@/domains/NewHome/components/TestimonialsSection/TestimonialsSection.styles';
import { HomeH2 } from '@/domains/NewHome/components/components.styles';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import HomeStore from '@/stores/HomeStore';
import MainContentVideoModal from '@/domains/Village/Tabs/HomeTab/HomeTabVideoModal';
import { THomeVideoTestimonial } from '@/types/Content/Home';

// const slidesToShow = { xl: 3, lg: 2, md: 1 };

const TestimonialsSection: React.FC = () => {
  const theme = useTheme();
  const { homeContent } = HomeStore;
  // const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  // const sliderRef = useRef(null);
  // const settings: Settings = {
  //   dots: true,
  //   slidesToShow: slidesToShow.xl,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   infinite: false,
  //   responsive: [
  //     {
  //       breakpoint: theme.breakpoints.values.lg,
  //       settings: {
  //         slidesToShow: slidesToShow.lg,
  //       },
  //     },
  //     {
  //       breakpoint: theme.breakpoints.values.md,
  //       settings: {
  //         slidesToShow: slidesToShow.md,
  //       },
  //     },
  //   ],
  // };

  const [openVideo, setOpenVideo] = useState<THomeVideoTestimonial | null>(
    null,
  );

  // const showNavButtons = useMemo(() => {
  //   if (matchesLg) {
  //     return slidesToShow.xl < homeContent?.video_testimonials?.length;
  //   }
  //   if (matchesSm) {
  //     return slidesToShow.lg < homeContent?.video_testimonials?.length;
  //   }
  //   return slidesToShow.md < homeContent?.video_testimonials?.length;
  // }, [homeContent?.video_testimonials?.length, matchesLg, matchesSm]);

  // const gotoNext = () => {
  //   sliderRef?.current?.slickNext();
  // };

  // const gotoPrev = () => {
  //   sliderRef?.current?.slickPrev();
  // };

  if (!homeContent?.video_testimonials?.length) {
    return <></>;
  }

  return (
    <TestimonialsWrapper>
      <Container>
        <Stack alignItems="center">
          <HomeH2
            align="center"
            style={xw`relative`}
            sx={{
              fontSize: {
                xs: `${theme.spacing(9.5)} !important`,
                md: `${theme.spacing(12.5)} !important`,
              },
              lineHeight: `1.1 !important`,
              color: theme.palette.warning[900],
            }}
          >
            OneVillage in the Words of End Users
            {/* {showNavButtons && (
            <TestimonialsSwiperNav>
              <TestimonialsSwiperNavBtn onClick={gotoPrev}>
                <Icon iconName="chevron-left" />
              </TestimonialsSwiperNavBtn>
              <TestimonialsSwiperNavBtn onClick={gotoNext}>
                <Icon iconName="chevron-right" />
              </TestimonialsSwiperNavBtn>
            </TestimonialsSwiperNav>
          )} */}
          </HomeH2>

          <Box width="100%" maxWidth="90%">
            <Box
              position="relative"
              width="100%"
              sx={{
                position: 'relative',
                margin: theme.spacing(6, 0),
                cursor: 'pointer',
                paddingBottom: matchesSm ? '30%' : '90%',
              }}
              onClick={() =>
                setOpenVideo({ youtubeVideoId: 'd8whhGgRtLk', image: null })
              }
            >
              <Image
                src={
                  matchesSm
                    ? '/images/home/testimonials-videos.png'
                    : '/images/home/testimonials-mobile.png'
                }
                layout="fill"
                objectFit="contain"
                objectPosition="center center"
              />
            </Box>
          </Box>
        </Stack>

        {/* <TestimonialsSlider>
          <Slider {...settings} ref={sliderRef}>
            {homeContent.video_testimonials?.map((item) => (
              <Stack
                key={item.youtubeVideoId}
                className="testimonial-item"
                flexDirection="row"
                sx={{ display: 'flex !important' }}
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                <VideoImageWrapper
                  sx={{ backgroundColor: item.image?.dominantColor }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setOpenVideo(item)}
                >
                  <VideoImage
                    layout="fill"
                    src={item.image?.url}
                    alt={item.image?.alt}
                    objectFit="cover"
                  />
                  <Icon
                    iconName="play"
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      fontSize: theme.spacing(7),
                    }}
                    {...{ pointerEvents: 'none' }}
                  />
                </VideoImageWrapper>
              </Stack>
            ))}
          </Slider>
        </TestimonialsSlider> */}
      </Container>
      <MainContentVideoModal
        opened={!!openVideo?.youtubeVideoId}
        closeModal={() => setOpenVideo(null)}
        youtubeVideoId={openVideo?.youtubeVideoId}
        title={openVideo?.image?.alt}
      />
    </TestimonialsWrapper>
  );
};

export default TestimonialsSection;
