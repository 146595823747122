import { useEffect } from 'react';
import { useRouter } from 'next/router';

import FridgeDoorStore from '@/stores/FridgeDoorStore';
import AuthStore from '@/stores/AuthStore';
import { COMPANY_PATH } from '@/common/constants/routes';
import { getLandingCoachSlugQuery } from '@/utils/common';

export const useRedirectToLoggedIn = () => {
  const router = useRouter();
  const { isLoggedIn, isOrganizationMember, cmsUser } = AuthStore;
  const { currentVillagePath } = FridgeDoorStore;

  useEffect(() => {
    if (isOrganizationMember) {
      const organizationRel = cmsUser.organizationsRels.find(
        (item) => item.role === 'member',
      );
      router.push(`${COMPANY_PATH}/${organizationRel.organization.slug}`);
      return;
    }

    if (isLoggedIn && currentVillagePath) {
      router.push({
        pathname: `${currentVillagePath}/home`,
        query: getLandingCoachSlugQuery(router),
      });
    }
  }, [isOrganizationMember, isLoggedIn, currentVillagePath]);
};
