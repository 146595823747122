import React from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import {
  HeroHeader,
  getLeftTransition,
} from '@/domains/NewHome/components/HeroSection/HeroSection.styles';
import Icon from '@/components/icons/Icon';
import { CalendlyEmbeded } from '@/components/CalendlyEmbeded';

type Props = {
  moveLeft: boolean;
  setVideoOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeroLeftContent = ({ moveLeft, setVideoOpened }: Props) => {
  const theme = useTheme();

  return (
    <Stack
      flex={{ xs: 'unset', sm: '1' }}
      sx={{
        position: 'relative',
        zIndex: 1,
        color: '#fff',
        // border: '1px solid red',
        ...getLeftTransition(moveLeft),
      }}
      direction="column"
      justifyContent={{ xl: 'center' }}
      paddingTop={{ xs: theme.spacing(6), lg: theme.spacing(18), xl: 0 }}
      paddingBottom={{
        xs: theme.spacing(10),
        sm: theme.spacing(6),
        xl: theme.spacing(16),
      }}
    >
      <HeroHeader textAlign={{ xs: 'center', sm: 'left' }}>
        A dedicated expert care team for you and your loved ones
      </HeroHeader>
      <Typography
        variant="body1"
        textAlign={{ xs: 'center', sm: 'left' }}
        sx={{
          marginTop: theme.spacing(4),
          fontSize: {
            xs: `${theme.spacing(5)} !important`,
            sm: `${theme.spacing(4.5)} !important`,
            md: `${theme.spacing(4.3)} !important`,
            lg: `${theme.spacing(5)} !important`,
          },
          lineHeight: `1.4 !important`,
        }}
      >
        OneVillage is your personalized navigation and coaching platform.
        Designed to cater to your individual needs, we offer customized support
        for life and health events, ensuring you have the guidance you need
        every step of the way.
      </Typography>
      <Stack
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        sx={{
          marginTop: theme.spacing(11),
          marginX: { xs: 'auto', sm: 'unset' },
          gap: theme.spacing(4),
          alignSelf: 'flex-start',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setVideoOpened((prev) => !prev)}
          sx={{
            paddingX: theme.spacing(10),
            color: theme.palette.grey[900],
            minWidth: theme.spacing(42),
          }}
          startIcon={
            <Icon
              iconName="play"
              style={{ marginRight: theme.spacing(1), fontSize: '14px' }}
            />
          }
        >
          Take A Tour
        </Button>
        <CalendlyEmbeded
          color="secondary"
          sx={{
            paddingX: theme.spacing(10),
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.grey[50],
            minWidth: theme.spacing(42),
          }}
        >
          Book a Demo
        </CalendlyEmbeded>
      </Stack>
    </Stack>
  );
};

export default HeroLeftContent;
